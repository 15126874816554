<template>
    <modal ref="modalIngresarMaterial" tamano="modal-md" titulo="Ingresar material" adicional="Ingresar" @adicional="ingresarMaterial">
        <ValidationObserver ref="materialEnteringObserver" >
            <p class="mb-4 mx-5">
                <required text="Campos obligatorios"/>
            </p>
            <div class="row justify-content-center mb-3 f-15">
                <div class="col-10 mb-2">
                    <p class="input-label-top">Material <required/></p>
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                        <el-select v-model="inputNombreMaterial" filterable class="f-12 br-5 w-100" size="small">
                            <el-option
                            v-for="material in select_materials"
                            :key="material.id"
                            :label="material.nombre"
                            :value="material.id"
                            />
                        </el-select>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-10 my-2">
                    <p class="input-label-top">Unidades <required/></p>
                    <ValidationProvider v-slot="{ errors }" name="unidades" rules="required|numeric">
                        <el-input v-model="inputCanUnidades" class="f-12 br-5 w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-10 my-2">
                    <p class="input-label-top">Bodega <required/></p>
                    <ValidationProvider v-slot="{ errors }" name="bodega" rules="required">
                        <el-select v-model="inputBodega" filterable class="f-12 br-5 w-100" size="small">
                            <el-option
                            v-for="bodegas in bodegasInStock"
                            :key="bodegas.id"
                            :label="bodegas.nombre"
                            :value="bodegas.id"
                            :disabled="bodegas.estado_bodega == 1 ? false : true"
                            />
                        </el-select>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
				<div class="col-4 my-2 pr-0">
                    <p class="input-label-top">Tipo solicitante </p>
					<el-select v-model="inputTipoSolicitante" filterable class="f-12 br-5 w-100" size="small">
						<el-option
						v-for="socilitante in tipo_solicitante"
						:key="socilitante.id"
						:label="socilitante.nombre"
						:value="socilitante.id"
						/>
					</el-select>
                </div>
				<div class="col-6 my-2">
                    <p class="input-label-top">{{inputTipoSolicitante == 1 ? 'Solicitante' : 'Taller'}} </p>
					<el-select v-model="inputTallante[inputTipoSolicitante-1]" filterable class="f-12 br-5 w-100" size="small">
						<el-option
						v-for="taller in inputTipoSolicitante == 1 ? select_users_internos : select_talleres"
						:key="taller.id"
						:label="taller.nombre"
						:value="taller.id"
						/>
					</el-select>
                </div>
				<div class="col-10 my-2">
                    <p class="input-label-top">Proyecto</p>
					<el-select v-model="inputProject" filterable class="f-12 br-5 w-100" size="small">
						<el-option
						v-for="project in select_all_projects"
						:key="project.id"
						:label="project.nombre"
						:value="project.id"
						/>
					</el-select>
                </div>
                <div class="col-10 my-2">
                    <p class="input-label-top">Comentarios</p>
					<el-input v-model="inputComentarios" type="textarea" :autosize="{ minRows: 3}" class="f-12 br-5 w-100" size="small" />
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {Notification} from 'element-ui'
export default {
    props: {
        materialsInStock: {
            type: Array,
            default: () => []
        },
        // solicitante: {
        //     type: Array,
        //     default: () => []
        // },
        // taller: {
        //     type: Array,
        //     default: () => []
        // },
        // project: {
        //     type: Array,
        //     default: () => []
        // },
        bodegasInStock: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            inputNombreMaterial: '',
            inputBodega: '',
            inputCanUnidades: '',
            inputTipoSolicitante: '',
			inputTallante: [null, null],
            inputProject: '',
            inputComentarios: '',
			tipo_solicitante:[
                {
                    id: 1,
                    nombre: 'Usuario'
                },
                {
                    id: 2,
                    nombre: 'Taller'
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            TIPO_INGRESO: 'almacen/stock/TIPO_INGRESO',
            select_materials: 'selects/selects/select_materials',
            select_talleres: 'selects/selects/select_talleres',
            select_users_internos: 'selects/selects/select_users_internos',
            select_all_projects: 'selects/selects/select_all_projects'
        }),
    },
	watch: {
        inputTipoSolicitante(val) {
            if (val == 2) this.inputTallante[0]  =  null
            else this.inputTallante[1]  =  null
        }
    },
    methods: {
		...mapActions({
            Action_get_selects_talleres: 'selects/selects/Action_get_selects_talleres',
            Action_get_selects_users_internos: 'selects/selects/Action_get_selects_users_internos',
            Action_get_selects_all_projects: 'selects/selects/Action_get_selects_all_projects',
        }),
        toggle(){
            this.$refs.modalIngresarMaterial.toggle()
            this.cleanFields()
			this.listarSelects()
        },
		async listarSelects(){
            await this.Action_get_selects_talleres()
            await this.Action_get_selects_users_internos()
            await this.Action_get_selects_all_projects()
        },
        async ingresarMaterial(){
            try {
                const materialEnteringFieldsAreOK = await this.$refs.materialEnteringObserver.validate()
                if(materialEnteringFieldsAreOK){
                    await this.$store.dispatch('almacen/stock/Action_in_out_operation_stock', {
                        id_material: parseInt(this.inputNombreMaterial),
                        unidades: parseInt(this.inputCanUnidades),
                        tipoSolicitante: parseInt(this.inputTipoSolicitante),
                        taller: parseInt(this.inputTallante[1]),
                        solicitante: parseInt(this.inputTallante[0]),
                        proyecto: parseInt(this.inputProject),
                        comentarios: this.inputComentarios,
                        id_bodega: this.inputBodega,
                        tipo: 1,
                    })
                    this.toggle()
                }
            } catch(e){
                this.toggle()
            }
            setTimeout(async () => {
                await this.$store.dispatch('almacen/stock/Action_get_rango_unidades_materiales')
                await this.$store.dispatch('almacen/stock/Action_list_stock_materials', 1)
                await this.$store.dispatch('almacen/stock/Action_get_cabecera_stock')
            }, 1);
        },
        cleanFields(){
            this.$refs.materialEnteringObserver.reset();
            this.inputNombreMaterial = ''
            this.inputCanUnidades = ''
            this.inputBodega = '',
            this.inputComentarios = ''
			this.inputTipoSolicitante = ''
			this.inputTallante[0] = ''
			this.inputTallante[1] = ''
			this.inputProject = ''
        }
    }
}
</script>

<style lang="css" scoped>
</style>
