<template>
    <article class="card" :style="`border: 1px solid ${color};`">
        <section class="left" :style="`background-color: ${color};`">
            <p>
                <span v-if="number" class="f-20 f-500">{{ number }}</span>
                <i v-if="icon" class="f-30" :class="icon"></i>
            </p>
        </section>
        <section class="right">
            <slot/>
        </section>
    </article>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#D4D4D4'
        },
        icon: String,
        number: Number,
    }
}
</script>


<style scoped lang="scss">
.card{
    display: flex;
    flex-direction: row;
    min-width: 230px;
    height: 55px;
    border-radius: 5px;
    overflow: hidden;
    .left{
        width: 25%;
        height: 100%;
        color: white;
        font-size: bold;
        display: grid;
        place-items: center;
    }
    .right{
        width: 75%;
        height: 100%;
        background-color: white;
    }
}
</style>