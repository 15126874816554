<template>
    <section class="almacen-materiales-stock px-3 container-fluid overflow-auto custom-scroll">
        <stock-header/>
        <titulo-divisor titulo="Materiales en stock">
            <div class="row">
                <div class="col auto">
                    <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar" size="small" />
                </div>
				<!-- descargar  -->
				<div class="col-auto icon-option my-auto px-1 mb-1" style="transform:rotate(180deg) !important">
                    <el-tooltip content="Descargar" effect="light" visible-arrow
                    >
					<!-- :class="permitAction('almacen.etapa.descargar.plantilla.configuracion') ? 'cr-pointer' : 'cr-not-allowed'"
					@click="functionPermitAction('almacen.etapa.descargar.plantilla.configuracion')" -->
                        <a href="#" class=" cr-pointer" @click.prevent="ExportarExcel" >
							<i class="icon-upload-outline download_template f-26"></i>
						</a>
						<!-- v-if="permitAction('almacen.etapa.descargar.plantilla.configuracion')" -->
                        <!-- <p v-else class="icon-upload-outline download_template f-26 cr-not-allowed"></p> -->
                    </el-tooltip>
                </div>
                <div class="col-auto my-auto px-1">
                    <button class="btn btn-general f-12 px-4"
                        :class="permitAction('almacen.stock.ingresar.material') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('almacen.stock.ingresar.material', ingresarMaterial)"
                    >Ingresar material</button>
                </div>
                <div class="col-auto my-auto px-1">
                    <button class="btn btn-general f-12 px-4"
                        :class="permitAction('almacen.stock.retirar.material') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('almacen.stock.retirar.material', retirarMaterial)"
                    >Retirar material</button>
                </div>
                <div class="col-auto my-auto pl-1 icon-option">
                    <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="handleClearFilter"/>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general 
            :usar-servidor="false" 
            :usar-paginacion="false"
            :mostrarBuscador="false" 
            altura-tabla="calc(100vh - 390px)" 
            :data="stockMaterials"
        >
            <el-table-column label="Ref" prop="material" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.referencia }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="material">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos cr-pointer f-600" @click="verInformacionMaterial(scope.row.id_material)">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="material" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo_material }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M." prop="material" width="80">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.unidad_medida }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor cotizado" prop="valor" align="center" width="120">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades disponibles" prop="unidades_disponibles" align="center">
                <template slot-scope="scope">
                    <p>{{ scope.row.unidades_disponibles ? scope.row.unidades_disponibles: '-' }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Valor total del material" prop="total" align="center">
                <template slot-scope="scope">
                    <p>{{ formatoMoneda(scope.row.valor_total) }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha último movimiento" prop="fecha_ultimo_movimiento" align="center">
                <template slot-scope="scope">
                    <p class="text-capitalize">{{  scope.row.fecha_ultimo_movimiento | helper-fecha('DD MMM Y')  }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades en movimiento " prop="unidades" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 d-middle-center cr-pointer" @click="verInformacionMaterial(scope.row.id_material)">
                        <i :class="`f-20 ${scope.row.tipo === 1  ? 'icon-login-variant text-success' : 'icon-logout-variant text-danger'} `" />
                        <span> {{ scope.row.unidades }} </span>
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <div class="row justify-content-center">
            <div class="col-auto">
                <Pagination :pagination="pagination" layout="prev, pager, next" @paginate="Action_list_stock_materials()"/>
            </div>
        </div>
        <!-- partials  -->
        <modal-ingresar-material ref="modalIngresarMaterial" :materials-in-stock="stockMaterials.data" :bodegas-in-stock="bodegas"/>
        <modal-retirar-material ref="modalRetirarMaterial" :materials-in-stock="stockMaterials.data" :bodegas-in-stock="bodegas"/>
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-4">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" placeholder="Seleccionar material" size="small" class="w-100" clearable>
                        <el-option v-for="item in materialTypes" :key="item.id" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <!-- <div class="col-12 my-4">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filter.id_unidad_medida" placeholder="Seleccionar material" size="small" class="w-100" clearable>
                        <el-option v-for="item in measure_units" :key="item.id" :label="`${item.unidad_medida} (${item.sigla})`" :value="item.id" />
                    </el-select>
                </div> -->
                <div class="col-6 my-3">
                    <p class="input-label-top">Fecha de inicio</p>
                    <el-date-picker v-model="filter.fecha_inicio" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <!-- value-format="yyyy-MM-DD" format="yyyy-MM-DD" -->
                <div class="col-6 my-3">
                    <p class="input-label-top">Fecha final</p>
                    <el-date-picker v-model="filter.fecha_fin" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_valor" range :min="rango_unidades.valor_min" :max="rango_unidades.valor_max" :format-tooltip="formatTooltipRange" />
                </div>
                <div class="col-12 my-4 d-middle-center">
                    <!-- <p class="input-label-top">Estado del material</p> -->
                    <el-checkbox-group v-model="filter.estado" class="my-4" fill="#0B56A7">
                        <el-checkbox :label="1" :border-radius="20">Activo</el-checkbox>
                        <el-checkbox :label="0" :border-radius="20">Inactivo</el-checkbox>
                    </el-checkbox-group>
                </div>
				<div class="col-5 my-2 pr-0">
					<p class="input-label-top">Tipo solicitante</p>
					<el-select v-model="filter.tipoSolicitante" placeholder="Seleccionar" size="small" class="w-100" clearable>
						<el-option v-for="item in tipo_solicitante" :key="item.id" :label="item.nombre" :value="item.id" />
					</el-select>
				</div>
				<div class="col-7 my-2">
					<p class="input-label-top">{{filter.tipoSolicitante == 1 ? 'Solicitante' : 'Taller'}}</p>
					<el-select v-if="filter.tipoSolicitante == 1" v-model="filter.solicitante" placeholder="Seleccionar" size="small" class="w-100" clearable>
						<el-option v-for="item in filter.tipoSolicitante == 1 ? select_users_internos : select_talleres" :key="item.id" :label="item.nombre" :value="item.id" />
					</el-select>
					<el-select v-else v-model="filter.taller" placeholder="Seleccionar" size="small" class="w-100" clearable>
						<el-option v-for="item in filter.tipoSolicitante == 1 ? select_users_internos : select_talleres" :key="item.id" :label="item.nombre" :value="item.id" />
					</el-select>
				</div>
				<div class="col-12 my-3">
					<p class="input-label-top">Proyecto</p>
					<el-select v-model="filter.proyecto" placeholder="Seleccionar un proyecto" size="small" class="w-100" clearable>
						<el-option v-for="item in select_all_projects" :key="item.id" :label="item.nombre" :value="item.id" />
					</el-select>
				</div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="handleClearFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import modalIngresarMaterial from './partials/modalIngresarMaterial.vue'
import modalRetirarMaterial from './partials/modalRetirarMaterial.vue'
import StockHeader from './header'
export default {
    components:{
		modalIngresarMaterial,
		modalRetirarMaterial,
		StockHeader
    },
	data(){
        return {
			tipo_solicitante:[
                {
                    id: 1,
                    nombre: 'Usuario'
                },
                {
                    id: 2,
                    nombre: 'Taller'
                }
            ]
        }
    },
	watch: {
        'filter.tipoSolicitante'(val) {
            if (val == 2) this.filter.solicitante = null
            else this.filter.taller = null
        }
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
            stockMaterials: 'almacen/stock/stockMaterials',
            bodegas: 'almacen/stock/bodegas',
            materialTypes: 'configurar/almacen/tipos_material/materialTypes',
            rango_unidades: 'almacen/stock/rango_unidades',
            measure_units: 'selects/selects/select_measure_units',
            select_talleres: 'selects/selects/select_talleres',
            select_users_internos: 'selects/selects/select_users_internos',
            select_all_projects: 'selects/selects/select_all_projects'
        }),
        id_tipo_material(){
            return this.$route.params.id ?? 0
        },
        filter: {
            get(){
                return this.$store.getters['almacen/stock/filter']
            },
            set(val){
                this.$store.commit('almacen/stock/set_filter', val)
            }
        },
        solicitanteTaller: {
            get(){
                return [this.filter.solicitante, this.filter.taller]
            },
            set(val){
                console.log("🚀 ~ file: index.vue:232 ~ set ~ val:", val)
				this.filter.solicitante = val[0]
				this.filter.taller = val[1]
            }
        },
        pagination: {
            get(){
                return this.$store.getters['almacen/stock/pagination']
            },
            set(val){
                this.$store.commit('almacen/stock/set_pagination', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['almacen/stock/query']
            },
            set(val){
                this.$store.commit('almacen/stock/set_query', val)
            }
        },
        range_valor: {
            get(){
                return [
                    this.filter.valor_inicial,
                    this.filter.valor_final
                ]
            },
            set(val){
                this.filter.valor_inicial = val[0]
                this.filter.valor_final = val[1]
            }
        },
        filterCount(){
            return this.filter.count()
        }
    },
    async mounted(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.stock' ]);
        this.getSelects(['Action_get_select_materials','Action_get_select_measure_units'])
        await Promise.all([
            this.$store.dispatch('configurar/almacen/tipos_material/Action_list_material_types'),
            this.Action_get_rango_unidades_materiales(),
            this.Action_list_bodegas()
        ])
        await this.clearFilter()
        this.Action_list_stock_materials()
		this.listarSelects()
    },
    methods:{
        ...mapActions({
            getSelects: 'selects/selects/sync',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_list_stock_materials: 'almacen/stock/Action_list_stock_materials',
            Action_get_rango_unidades_materiales: 'almacen/stock/Action_get_rango_unidades_materiales',
            Action_list_bodegas: 'almacen/stock/Action_list_bodegas',
            clearFilter: 'almacen/stock/Action_clear_filter',
            Action_get_selects_talleres: 'selects/selects/Action_get_selects_talleres',
            Action_get_selects_users_internos: 'selects/selects/Action_get_selects_users_internos',
            Action_get_selects_all_projects: 'selects/selects/Action_get_selects_all_projects',
        }),
		async listarSelects(){
            await this.Action_get_selects_talleres()
            await this.Action_get_selects_users_internos()
            await this.Action_get_selects_all_projects()
        },
        beforeSearch(){
            this.delay(this.Action_list_stock_materials)
        },
        delay(callback, tiempo=500){
			if(typeof(callback) == 'function') {
				if(window['tiempo_gbp'] == undefined){
					window['tiempo_gbp'] = 0;
				}
				if (tiempo_gbp) {
					clearInterval(tiempo_gbp)
				}
				tiempo_gbp = setTimeout(()=>{
					callback();
				}, tiempo)
			} else {
				console.warn('El primer parámetro debe ser una función.');
			}
		},
        ingresarMaterial(){
            this.$refs.modalIngresarMaterial.toggle()
        },
        retirarMaterial(){
            this.$refs.modalRetirarMaterial.toggle()
        },
        verInformacionMaterial(id_material){
            this.$router.push({ name: 'almacen.materiales.ver.stock', params: {id: id_material} })
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async filtrarMateriales(){
            await this.Action_list_stock_materials()
            this.$refs.abrirModalFiltros.toggle()
        },
		async ExportarExcel() {
            try{
                await axios.get(`/almacen/stock/descargar-stock`,{
                    responseType : "blob",
                })
                    .then(response => {
                        const url = URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.setAttribute('download','AlmacenMaterialesStock.xlsx')
                        link.href = url
                        document.body.appendChild(link);
                        link.click()
                    })
                    .catch(err => {
                        console.log(err);
                    })

            }catch(e){
                console.log(e);
            }
        },
        handleClearFilter(){
            this.clearFilter()
            this.Action_list_stock_materials()
        }
    }
}
</script>

<style lang="scss" scoped>
.almacen-materiales-stock{
    height: calc(100vh - 240px);
    .estado{
        &-activo{
            color: #3CD273;
        }
        &-inactivo{
            color: #dddddd;
        }
    }
}
.el-checkbox__inner {
	border-radius: 50%;
}
.download_template{
        color: var(--color-5d);
        &:hover{
            text-decoration: none !important;
        }
    }
    .icon-option{
        .icon-plus-circle, .icon-text-box-plus-outline, .icon-upload-outline, .icon-trash-can-outline{
            &:hover{
                color: var(--color-general);
            }
        }
    }
</style>