var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalRetirarMaterial",attrs:{"tamano":"modal-md","titulo":"Retirar material","adicional":"Retirar"},on:{"adicional":_vm.retirarMaterial}},[_c('ValidationObserver',{ref:"materialRetiringObserver"},[_c('p',{staticClass:"mb-4 mx-5"},[_c('required',{attrs:{"text":"Campos obligatorios"}})],1),_c('div',{staticClass:"row justify-content-center mb-3 f-15"},[_c('div',{staticClass:"col-10 mb-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre del material "),_c('required')],1),_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","placeholder":"Seleccionar material","size":"small"},model:{value:(_vm.inputNombreMaterial),callback:function ($$v) {_vm.inputNombreMaterial=$$v},expression:"inputNombreMaterial"}},_vm._l((_vm.select_materials),function(material){return _c('el-option',{key:material.id,attrs:{"label":material.nombre,"value":material.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-10 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Unidades "),_c('required')],1),_c('ValidationProvider',{attrs:{"name":"unidades","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"f-12 br-5 w-100",attrs:{"size":"small"},on:{"change":function($event){return _vm.ingresoMaterial()}},model:{value:(_vm.inputCanUnidades),callback:function ($$v) {_vm.inputCanUnidades=$$v},expression:"inputCanUnidades"}}),(_vm.cantidadMaterial)?_c('p',{staticClass:"f-10 text-danger"},[_vm._v(" La cantidad ingresada supera las unidades disponibles en stock ")]):_vm._e(),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-10 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Bodega "),_c('required')],1),_c('ValidationProvider',{attrs:{"name":"bodega","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"f-12 br-5 w-100",attrs:{"filterable":"","size":"small"},model:{value:(_vm.inputBodega),callback:function ($$v) {_vm.inputBodega=$$v},expression:"inputBodega"}},_vm._l((_vm.bodegasInStock),function(bodegas){return _c('el-option',{key:bodegas.id,attrs:{"label":bodegas.nombre,"value":bodegas.id,"disabled":bodegas.estado_bodega == 1 ? false : true}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-4 my-2 pr-0"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Solicitante ")]),_c('el-select',{staticClass:"f-12 br-5 w-100",attrs:{"filterable":"","size":"small"},model:{value:(_vm.inputTipoSolicitante),callback:function ($$v) {_vm.inputTipoSolicitante=$$v},expression:"inputTipoSolicitante"}},_vm._l((_vm.tipo_solicitante),function(socilitante){return _c('el-option',{key:socilitante.id,attrs:{"label":socilitante.nombre,"value":socilitante.id}})}),1)],1),_c('div',{staticClass:"col-6 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v(_vm._s(_vm.inputTipoSolicitante == 1 ? 'Solicitante' : 'Taller')+" ")]),_c('el-select',{staticClass:"f-12 br-5 w-100",attrs:{"filterable":"","size":"small"},model:{value:(_vm.inputTallante[_vm.inputTipoSolicitante-1]
					),callback:function ($$v) {_vm.$set(_vm.inputTallante, _vm.inputTipoSolicitante-1, $$v)},expression:"inputTallante[inputTipoSolicitante-1]\n\t\t\t\t\t"}},_vm._l((_vm.inputTipoSolicitante == 1 ? _vm.select_users_internos : _vm.select_talleres),function(taller){return _c('el-option',{key:taller.id,attrs:{"label":taller.nombre,"value":taller.id}})}),1)],1),_c('div',{staticClass:"col-10 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Proyecto")]),_c('el-select',{staticClass:"f-12 br-5 w-100",attrs:{"filterable":"","size":"small"},model:{value:(_vm.inputProject),callback:function ($$v) {_vm.inputProject=$$v},expression:"inputProject"}},_vm._l((_vm.select_all_projects),function(project){return _c('el-option',{key:project.id,attrs:{"label":project.nombre,"value":project.id}})}),1)],1),_c('div',{staticClass:"col-10 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Comentarios "),_c('required')],1),_c('el-input',{staticClass:"f-12 br-5 w-100",attrs:{"type":"textarea","autosize":{ minRows: 3},"size":"small"},model:{value:(_vm.inputComentarios),callback:function ($$v) {_vm.inputComentarios=$$v},expression:"inputComentarios"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }