<template>
    <modal ref="modalRetirarMaterial" tamano="modal-md" titulo="Retirar material" adicional="Retirar" @adicional="retirarMaterial">
        <ValidationObserver ref="materialRetiringObserver" >
            <p class="mb-4 mx-5">
                <required text="Campos obligatorios"/>
            </p>
            <div class="row justify-content-center mb-3 f-15">
                <div class="col-10 mb-2">
                    <p class="input-label-top">Nombre del material  <required/></p>
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                        <el-select v-model="inputNombreMaterial" filterable placeholder="Seleccionar material" size="small" class="w-100">
                            <el-option
                            v-for="material in select_materials"
                            :key="material.id"
                            :label="material.nombre"
                            :value="material.id"
                            />
                        </el-select>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-10 my-2">
                    <p class="input-label-top">Unidades  <required/></p>
                    <ValidationProvider v-slot="{ errors }" name="unidades" rules="required|numeric">
                        <el-input v-model="inputCanUnidades" class="f-12 br-5 w-100" size="small" @change="ingresoMaterial()"/>
                        <p v-if="cantidadMaterial" class="f-10 text-danger"> La cantidad ingresada supera las unidades disponibles en stock </p>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-10 my-2">
                    <p class="input-label-top">Bodega  <required/></p>
                    <ValidationProvider v-slot="{ errors }" name="bodega" rules="required">
                        <el-select v-model="inputBodega" filterable class="f-12 br-5 w-100" size="small">
                            <el-option
                            v-for="bodegas in bodegasInStock"
                            :key="bodegas.id"
                            :label="bodegas.nombre"
                            :value="bodegas.id"
                            :disabled="bodegas.estado_bodega == 1 ? false : true"
                            />
                        </el-select>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
				<div class="col-4 my-2 pr-0">
                    <p class="input-label-top">Solicitante </p>
					<el-select v-model="inputTipoSolicitante" filterable class="f-12 br-5 w-100" size="small">
						<el-option
						v-for="socilitante in tipo_solicitante"
						:key="socilitante.id"
						:label="socilitante.nombre"
						:value="socilitante.id"
						/>
					</el-select>
                </div>
				<div class="col-6 my-2">
                    <p class="input-label-top">{{inputTipoSolicitante == 1 ? 'Solicitante' : 'Taller'}} </p>
					<el-select v-model="inputTallante[inputTipoSolicitante-1]
					" filterable class="f-12 br-5 w-100" size="small">
						<el-option
						v-for="taller in inputTipoSolicitante == 1 ? select_users_internos : select_talleres"
						:key="taller.id"
						:label="taller.nombre"
						:value="taller.id"
						/>
					</el-select>
                </div>
				<div class="col-10 my-2">
                    <p class="input-label-top">Proyecto</p>
					<el-select v-model="inputProject" filterable class="f-12 br-5 w-100" size="small">
						<el-option
						v-for="project in select_all_projects"
						:key="project.id"
						:label="project.nombre"
						:value="project.id"
						/>
					</el-select>
                </div>
                <div class="col-10 my-2">
                    <p class="input-label-top">Comentarios <required/></p>
					<el-input v-model="inputComentarios" type="textarea" :autosize="{ minRows: 3}" class="f-12 br-5 w-100" size="small" />
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Notification } from 'element-ui'

export default {
    props: {
        materialsInStock: {
            type: Array,
            default: () => []
        },
        bodegasInStock: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            inputNombreMaterial: '',
            inputBodega: '',
            inputCanUnidades: '',
            inputTipoSolicitante: '',
			inputTallante: [null, null],
            inputProject: '',
            inputComentarios: '',
			cantidadMaterial:false,
			tipo_solicitante:[
                {
                    id: 1,
                    nombre: 'Usuario'
                },
                {
                    id: 2,
                    nombre: 'Taller'
                }
            ]
        }
    },
    computed:{
        ...mapGetters({
            TIPO_EGRESO: 'almacen/stock/TIPO_EGRESO',
            select_materials: 'selects/selects/select_materials',
			select_materials: 'selects/selects/select_materials',
            select_talleres: 'selects/selects/select_talleres',
            select_users_internos: 'selects/selects/select_users_internos',
            select_all_projects: 'selects/selects/select_all_projects'
        }),
    },
	watch: {
        inputTipoSolicitante(val) {
            if (val == 2) this.inputTallante[0]  =  null
            else this.inputTallante[1]  =  null
        }
    },
    methods: {
		...mapActions({
            Action_get_selects_talleres: 'selects/selects/Action_get_selects_talleres',
            Action_get_selects_users_internos: 'selects/selects/Action_get_selects_users_internos',
            Action_get_selects_all_projects: 'selects/selects/Action_get_selects_all_projects',
        }),
        toggle(){
            this.$refs.modalRetirarMaterial.toggle()
            this.cleanFields()
			this.listarSelects()
        },
		async listarSelects(){
            await this.Action_get_selects_talleres()
            await this.Action_get_selects_users_internos()
            await this.Action_get_selects_all_projects()
        },
        ingresoMaterial(){
            const matSelect = this.select_materials.find(el => el.id == this.inputNombreMaterial)
            this.cantidadMaterial = this.inputCanUnidades > matSelect.totalUnidades ? true : false
        },
        async retirarMaterial(){
            try {
                const retiringFieldsAreOk = await this.$refs.materialRetiringObserver.validate()
                if(this.cantidadMaterial) {
                    new Notification({
                        message:'La cantidad ingresada supera las unidades disponibles en stock',
                        type:'error',
                        dangerouslyUseHTMLString: false
                    })
                    return
                }
                if(retiringFieldsAreOk){
                    await this.$store.dispatch('almacen/stock/Action_in_out_operation_stock', {
                        id_material: parseInt(this.inputNombreMaterial),
                        unidades: parseInt(this.inputCanUnidades),
						tipoSolicitante: parseInt(this.inputTipoSolicitante),
                        taller: parseInt(this.inputTallante[1]),
                        solicitante: parseInt(this.inputTallante[0]),
                        proyecto: parseInt(this.inputProject),
                        comentarios: this.inputComentarios,
                        id_bodega: this.inputBodega,
                        tipo: 2,
                    })
                    this.toggle()
                }
            } catch(e){
                this.$notify({title: 'Error', message: 'Error al realizar el egreso', type: 'error'})
                this.toggle()
            }
            setTimeout(async () => {
                await this.$store.dispatch('almacen/stock/Action_get_rango_unidades_materiales')
                await this.$store.dispatch('almacen/stock/Action_list_stock_materials')
                await this.$store.dispatch('almacen/stock/Action_get_cabecera_stock')
            }, 1);
        },
        cleanFields(){
            this.$refs.materialRetiringObserver.reset();
            this.inputNombreMaterial = ''
            this.inputBodega = '',
            this.inputCanUnidades = ''
            this.inputComentarios = ''
			this.inputTipoSolicitante = ''
			this.inputTallante[0] = ''
			this.inputTallante[1] = ''
			this.inputProject = ''
        }
    }
}
</script>

<style lang="css" scoped>
</style>
