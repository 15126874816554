<template>
    <section>
        <titulo-divisor titulo="Información general del estado del inventario">
            <article class="d-flex br-5 overflow-hidden" style="border: #0B56A7 1px solid" >
                <p class="d-middle text-white px-3" style="background-color: #0B56A7">
                    <span class="f-12">Valor total de inventario</span>
                </p>
                <p class="f-18 f-600 py-1 pl-4 pr-2">{{ formatoMoneda(cabeceraStock.valor_total) }}</p>
            </article>
        </titulo-divisor>
        <div class="header">
            <section class="d-flex flex-column gap-1">
                <p class="f-10 f-500 ml-2">Rango de fecha</p>
                <div class="d-middle gap-3">
                    <el-select 
                    v-model="date_range" 
                    size="mini" 
                    style="width: 150px"
                    @change="calcularFechas"
                    >
                        <el-option 
                        v-for="(date, index) in  select_date_range" 
                        :key="index" 
                        :value="date.id" 
                        :label="date.text"
                        >
                        </el-option>
                    </el-select>
                    <el-date-picker
                    v-model="date"
                    size="mini"
                    prefix-icon="icon-calendar-today"
                    style="width: 230px"
                    type="daterange" 
                    range-separator="a"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="calcularFechasRango"
                    />
                </div>
            </section>
            <section class="d-flex flex-wrap gap-4">
                <HeaderCard color="#FF2449" :number="cabeceraStock.total_salidas">
                    <p class="d-middle h-100 pl-2">
                        <el-tooltip content="Salidas" effect="light" :visible-arrow="false">
                            <i class="icon-login-variant f-22" style="color: #FF2449"/>
                        </el-tooltip>
                        <span class="f-11"> Total de movimientos</span>
                    </p>
                </HeaderCard>
                <HeaderCard color="#0B56A7" icon="icon-dolar">
                    <div class="d-middle justify-content-end h-100 mr-2">
                        <p class="d-middle flex-column text-right lh-18">
                            <span class="f-20 f-500" style="color: #0B56A7;">{{ formatoMoneda(cabeceraStock.valor_salidas) }}</span>
                            <span class="f-11 w-100">Valor total de salidas</span>
                        </p>
                    </div>
                </HeaderCard>
                <HeaderCard color="#3CD273" :number="cabeceraStock.total_entradas">
                    <p class="d-middle h-100 pl-2">
                        <el-tooltip content="Entradas" effect="light" :visible-arrow="false">
                            <i class="icon-login-variant f-22" style="color: #3CD273"/>
                        </el-tooltip>
                        <span class="f-11"> Total de movimientos</span>
                    </p>    
                </HeaderCard>
                <HeaderCard color="#0B56A7" icon="icon-dolar">
                    <div class="d-middle justify-content-end h-100 mr-2">
                        <p class="d-middle flex-column text-right lh-18">
                            <span class="f-20 f-500" style="color: #0B56A7;">{{ formatoMoneda(cabeceraStock.valor_entradas) }}</span>
                            <span class="f-11 w-100">Valor total de entradas</span>
                        </p>
                    </div>
                </HeaderCard>  
            </section>
        </div>
    </section>
</template>

<script>
import HeaderCard from './components/headerCard.vue'
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
export default {
    components: {
        HeaderCard
    },
    data() {
        return {
            date: [],
            date_range: '',
            inventory_total_value: 111200000,
            departures_total_value: [120000000, 540000000],
            total_movements: [45, 87],
            select_date_range: [
                {
                    id: 0,
                    text: 'Último mes',
                }, 
                {
                    id: 1,
                    text: 'Último trimestre',
                }, 
                {
                    id: 2,
                    text: 'Último semestre',
                },
                {
                    id: 3,
                    text: 'Último año'
                }
            ],
        }
    },
    computed: {
        ...mapGetters({
            cabeceraStock: 'almacen/stock/cabeceraStock',
        }),
    },
    async mounted () {
        await this.Action_get_cabecera_stock()
    },
    methods: {
        ...mapActions({
            Action_get_cabecera_stock: 'almacen/stock/Action_get_cabecera_stock',
        }),
        async calcularFechas(idFecha){
            switch (idFecha) {
                case 0:
                    this.date = [] 
                    this.date[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
                    this.date[1] = moment().format('YYYY-MM-DD')
                    break;
                case 1:
                    this.date = [] 
                    this.date[0] = moment().subtract(3, 'M').format('YYYY-MM-DD')
                    this.date[1] = moment().format('YYYY-MM-DD')
                    break;
                case 2:
                    this.date = [] 
                    this.date[0] = moment().subtract(6, 'M').format('YYYY-MM-DD')
                    this.date[1] = moment().format('YYYY-MM-DD')
                    break;
                case 3:
                    this.date = [] 
                    this.date[0] = moment().subtract(12, 'M').format('YYYY-MM-DD')
                    this.date[1] = moment().format('YYYY-MM-DD')
                    break;
            }
            await this.Action_get_cabecera_stock({fecha_inicio:this.date[0], fecha_fin:this.date[1]})
        },
        async calcularFechasRango(){
            this.date_range = ''
			 
			if(this.date) await this.Action_get_cabecera_stock({fecha_inicio:this.date[0], fecha_fin:this.date[1]})
			else this.Action_get_cabecera_stock()
        }
    },
}
</script>

<style scoped lang="scss">
.header{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #D4D4D4;
    background-color: #F6F6F6;
    border-radius: 5px;
    padding: 1rem;
}
.text-gray{
    color: #c4c4c4 !important;
}
</style>